<template>
  <div>
    <v-container fluid grid-list-xs pa-0>
      <v-row wrap no-gutters>
        <v-col :cols="isPreviewEnabled ? 8 : 12">
          <template v-if="cloneItems.length">
            <v-container fluid grid-list-xs>
              <v-row wrap>
                <template
                  v-for="(
                    { name, type, creatorNote, filterKeys, _id, configuration }, index
                  ) of cloneItems"
                >
                  <v-col
                    :cols="isPreviewEnabled ? 4 : 3"
                    :key="name"
                    v-if="showSupplement(type)"
                  >
                    <template>
                      <SupplementCard
                        :name="name"
                        :type="type"
                        :filterKeys="filterKeys"
                        :creatorNote="creatorNote"
                        :configuration="configuration"
                      >
                        <template #action>
                          <template v-if="false && (suppSelectMode || selectedTab === 0)">
                            <v-checkbox
                              class="ml-3"
                              style="margin-top: 2px !important; height: 30px"
                              v-model="selectedSupplements"
                              @change="checkForIDP(type, $event, _id)"
                              color="indigo"
                              :value="_id"
                            ></v-checkbox>
                          </template>
                          <v-menu open-on-hover offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="menu-buttons-container mt-n1"
                                x-small
                                text
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="blue-grey darken-1">
                                  mdi-dots-vertical</v-icon
                                >
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                text
                                @click="openPreviewSupplement(index)"
                              >
                                <v-list-item-title>Preview</v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                text
                                color="orange darken-1"
                                @click="openAttachedSupStatusDialog(_id, index)"
                              >
                                <v-list-item-title>Info</v-list-item-title>
                              </v-list-item>
                              <!-- <v-list-item
                                text
                                color="orange darken-1"
                                @click="openAttachedSupStatusDialog(_id, index)"
                              >
                                <v-list-item-title>Detach</v-list-item-title>
                              </v-list-item> -->
                            </v-list>
                          </v-menu>
                        </template>
                        <!-- <template #description
                          ><div style="color: grey">
                            {{ creatorNote }}
                          </div></template
                        > -->
                      </SupplementCard>
                    </template>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-col>
              <p
                v-if="!isFetchingSupplements && !cloneItems.length"
                class="mt-2 display-1"
                style="text-align: center"
              >
                No Supplements available.
              </p>
              <p
                class="mt-2 loading display-1"
                style="text-align: center"
                v-else
              >
                Fetching Request
              </p>
            </v-col>
          </template>
          <v-col :cols="isPreviewEnabled ? 8 : 12">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              @input="onPageChange"
            />
          </v-col>
        </v-col>
        <v-col style="margin-top: 0.8%" cols="4" v-if="isPreviewEnabled">
          <v-card :style="`height: ${heightDiv}px`" outlined elevation="2">
            <div v-if="selectedSupplement" style="overflow-y: auto">
              <v-card-title style="padding: unset">
                <div title headline class="tabFont" style="padding-left: 10px">
                  {{ selectedSupplement.name }}
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="closePreviewSupplement">
                  <v-icon>mdi mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="overflow-y: auto">
                <div style="height: 70vh">
                  <v-card-text>
                    <SuplementRender :selectedSupplement="selectedSupplement" />
                  </v-card-text>
                  <v-card-text>{{ selectedSupplement }} </v-card-text> -->
                </div>
              </v-card-text>
            </div>
            <div v-else style="text-align: center; margin-top: 20px">
              <h3>Select a Supplement for preview...</h3>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Supplement Attachment Staus Dialog START  -->
    <v-dialog v-model="attachmentStatusDialog" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedSupplement ? selectedSupplement.name : 'NA' }} Status
        </v-card-title>

        <v-card-text>
          <!-- {{ selectedSupplementAttachmentStatus }} -->
          Report:-
          <ul>
            <li
              v-if="
                !selectedSupplementAttachmentStatus ||
                !selectedSupplementAttachmentStatus.report ||
                !selectedSupplementAttachmentStatus.report.length
              "
            >
              NA
            </li>
            <li
              v-else
              v-for="(i, idx) in selectedSupplementAttachmentStatus.report"
              :key="idx"
            >
              {{ i }}
            </li>
          </ul>
          Layouts:-
          <ul>
            <li
              v-if="
                !selectedSupplementAttachmentStatus ||
                !selectedSupplementAttachmentStatus.layouts ||
                !selectedSupplementAttachmentStatus.layouts.length
              "
            >
              NA
            </li>
            <li
              v-else
              v-for="(i, idx) in selectedSupplementAttachmentStatus.layouts"
              :key="idx"
            >
              {{ i }}
            </li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-2"
            text
            @click="attachmentStatusDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Supplement Attachment Staus Dialog END  -->
  </div>
</template>

<script>
import SupplementCard from './SupplementCard.vue'
import SuplementRender from './SuplementRender'

import { createNamespacedHelpers as cnh, mapState as mapRootState } from 'vuex'
const { mapState, mapMutations } = cnh('supplementStore')
export default {
  props: ['selectedTab'],
  components: {
    SupplementCard,
    SuplementRender
  },
  computed: {
    ...mapState([
      'isFetchingSupplements',
      'suppSelectMode',
      'selectedSupplements',
      'suppleAttachTo',
      'initialDefaultParams',
      'connectedSupplements',
      'connectedSupplementsIds'
    ]),
    ...mapRootState(['report']),

    pages() {
      return Math.ceil(this.pagination.total / this.pagination.rowsPerPage)
    },
    cloneItems() {
      let selSupps = []
      if (this.suppleAttachTo == 'All') {
        this.connectedSupplementsIds.forEach((el) => {
          let suppConf = this.connectedSupplements.find((c) => c._id == el);
          if(suppConf)  selSupps.push(suppConf) 
        })
      } else {
        this.selectedSupplements.forEach((el) => {
          let suppConf = this.connectedSupplements.find((c) => c._id == el);
          if(suppConf)  selSupps.push(suppConf) 
        })
      }
      var startFrom =
        this.pagination.page * this.pagination.rowsPerPage -
        this.pagination.rowsPerPage
      return selSupps.splice(startFrom, this.pagination.rowsPerPage)
    },
    selectedSupplement() {
      //TODO: rename this
      // this is for single select like preview or info
      return this.connectedSupplements[this.selectedSupplementIndex]
    }
  },
  data() {
    return {
      pagination: {
        page: 1,
        total: 0,
        rowsPerPage: 8
      },
      isPreviewEnabled: false,
      selectedSupplementIndex: -1,
      heightDiv: '80vh',
      testH: 90,
      attachmentStatusDialog: false,
      selectedSupplementAttachmentStatus: {},
      supplemntConnectedToMapping: {}
    }
  },
  methods: {
    ...mapMutations(['setSelectedSupplArray', 'setInitailDefaultParams']),
    openPreviewSupplement(index) {
      this.isPreviewEnabled = true
      this.selectedSupplementIndex = index
    },
    closePreviewSupplement() {
      this.isPreviewEnabled = false
      this.selectedSupplementIndex = -1
    },
    openAttachedSupStatusDialog(_id, selectedSupplementIndex = -1) {
      // show info of supp
      this.selectedSupplementIndex = selectedSupplementIndex
      this.attachmentStatusDialog = true
      this.selectedSupplementAttachmentStatus =
        this.supplemntConnectedToMapping[_id]
    },
    deatchSupplement() {
      console.log(this.suppleAttachTo, '--------------------')
    },
    checkForIDP(type, value, val) {
      if (type == 'initialDefaultParams' && value.includes(val)) {
        let IDP = this.connectedSupplements.find((el) => el._id == val)
        if (IDP && IDP.type == 'initialDefaultParams') {
          console.log(
            { type, value, val },
            this.connectedSupplements.find((el) => el._id == val)
          )
          let initialDefaultParamsClone = JSON.parse(
            JSON.stringify(this.initialDefaultParams)
          )
          initialDefaultParamsClone.push(val)
          this.setInitailDefaultParams(initialDefaultParamsClone)
        }
      }
    },
    showSupplement(type) {
      // remove supplement on the basis of type
      if (this.suppleAttachTo == 'Layout' && type == 'initialDefaultParams') {
        return false
      }
      return true
    },
    onPageChange(event) {},
    setWIndowHeight() {
      this.heightDiv =
        document.documentElement.clientHeight * 0.8 -
        (window.devicePixelRatio % 1) * 100

      this.testH = {
        pixel: window.devicePixelRatio,
        H: document.documentElement.clientHeight
      }
      window.onresize = function (e) {
        var event = window.event || e
        if (typeof window.onzoom === 'function') {
          return window.onzoom.call(window, event)
        }
      }
      window.onzoom = (e) => {
        console.log('zoom')
        this.heightDiv =
          document.documentElement.clientHeight * 0.8 -
          (window.devicePixelRatio % 1) * 100

        this.testH = {
          pixel: window.devicePixelRatio,
          H: document.documentElement.clientHeight
        }
      }
    }
  },
  created() {
    this.setWIndowHeight()
  },
  watch: {
    report(nv) {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-buttons-container {
  height: 28px !important;
  width: 27px !important;
  color: $mine-shaft;
}
</style>

</style>
